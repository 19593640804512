import { get } from 'lodash';
import CalendarSingleDatePicker from '../components/utils/calendar_single_date_picker';
import { getHumanizeTime } from '../utils/date_utils';

export default (passedProps) => {
    const { field, form, label, isRequired, disabled, valueFormatter, ...props } = passedProps;
    const error = get(form.errors, field.name, '');
    const touched = get(form.touched, field.name, '');
    const errorClass = error ? 'form-control-label text-danger' : '';
    const onStartDateSelected = (startDate) => {
        if (valueFormatter) {
            form.setFieldValue(field.name, startDate ? valueFormatter(startDate) : startDate);
        } else {
            form.setFieldValue(field.name, startDate);
        }
        if (props.handleOnDateSelected) {
            props.handleOnDateSelected(startDate);
        }
    };
    return (
        <div className={props.className}>
            {label && (
                <div className={`mb-2 ${errorClass} label`}>
                    {label}
                    {isRequired && <abbr className="text-danger">*</abbr>}
                </div>
            )}
            <CalendarSingleDatePicker
                onDateSelected={!disabled ? onStartDateSelected : undefined}
                startDate={field.value}
                {...props}
                {...field}
                containerStyles={{
                    minHeight: '38px',
                    height: '38px',
                    opacity: disabled ? 0.5 : 1,
                    pointerEvents: disabled ? 'none' : 'auto',
                }}
            />
            {error && <div className="text-help text-danger">{error}</div>}
        </div>
    );
};
