import { showIot } from 'components/dashboard/settings/alerts/alert_utils';
import { get, includes } from 'lodash';
export const ENABLED_EMAILS_VT_DOWNLOAD = ['diljeet.singh@fleetx.io', 'krishna.upadhyay@fleetx.io'];
export const isVtDownloadEnabled = () => {
    const email = get(window, 'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalEmailId');
    return includes(ENABLED_EMAILS_VT_DOWNLOAD, email);
};
export const showVtDownloadButton = () => {
    const isSwitchedUser = get(window, 'FLEETX_LOGGED_IN_DATA.isSwitchedUser', false);
    return showIot() || !isSwitchedUser || isVtDownloadEnabled();
};
