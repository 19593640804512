import { Field as FormikField } from 'formik';
import { Field } from 'redux-form';
import {
    compact,
    filter,
    find,
    first,
    floor,
    forEach,
    get,
    includes,
    isEmpty,
    join,
    keyBy,
    keys,
    last,
    map,
    padStart,
    pick,
    times,
    toSafeInteger,
    toUpper,
    uniq,
    unset,
    upperFirst,
} from 'lodash';
import { ALL_DAYS_TYPE, DATE_FORMAT_TIME_24, getHumanizeTime, getMomentTime } from './date_utils';
import {
    BATTERY_STATUS,
    BUSINESS_TYPES,
    CN_NATURE_TYPES,
    combineNameAddress,
    EXPENSE_TYPES,
    getAddress,
    getImageKitLink,
    INVOICE_STATUS,
    JOB_BOOKING_SORT_BASED_ON,
    parseAddress,
    REVERSE_TRIP,
    TIMESTAMP_FORMATS,
    TRANSACTION_TYPES,
    TRANSACTION_TYPES_WITH_LABELS,
    TRIP_SHEET_STATUS,
    TRUCK_LOAD_TYPE,
    TYRE_ATTACHED_TYPES,
    TYRE_STATUS_V2,
    VEHICLE_OWNERSHIP_TYPE,
    WORK_MODE_NEW_LIST,
} from '../constant';
import { FxSelectField, FxSelectSingleDate, FxTextFieldStackedLabel } from '../shared';
import { fetchAllTransporters } from '../actions/dashboard/finances/transporter_actions';
import { fetchVehicleListMini } from '../actions/dashboard/vehicles_action';
import { Col } from 'reactstrap';
import { fetchTags } from '../actions/dashboard/tags_action';
import { fetchUsersByType } from '../actions/dashboard/users_action';
import { fetchLastJobAllVehicles } from '../actions/dashboard/route_dispatch/jobs_action';
import {
    FLEETX_ACCOUNTS,
    getAccountId,
    getSystemTagIdForFiltering,
    isJobsDisabledForNonTrackInFreightSettlement,
    isMaerskAccount,
    isShowCodeInCustomerNameForAccount,
    showCustomerFilterIndents,
    showEmployeeCodeInUserSelect,
} from './account_utils';
import { fetchConsignments } from 'actions/dashboard/vendor_management/tmsConsignmentsActions';
import { CONSIGNMENT_NATURES } from 'utils/tmsConsignmentsUtils';
import { fetchAddressBook } from 'actions/dashboard/route_dispatch/address_book_action';
import { arrayToObject } from 'utils/list_utils';
import { GST_NATURES } from './consignmentsUtils';
import { isAccountIdGreaterThan10K } from 'utils/transactionUtils';
import { getAddressBookCategoryOptions } from 'utils/address_book_utils';
import { COLOR_ERROR } from '../theme/colors';
import { isAllowOnlyPONumberJobsInFreightSettlement } from 'utils/vendor_utils';
import { excludeVehicleStatusValue } from './accountUtils/vehicles';
import { FxForm, FxInput, FxTypography } from 'sharedV2';
import { isOrientCementAccount } from './accountUtils/common';

export const parseToInt = (value) => (value === undefined ? undefined : parseInt(value));

// export const parseToInt = value => !value ? null : Number(value)

export function renderCheckBox(type, displayText) {
    return (
        <label style={{ marginRight: '.5rem' }} key={type}>
            <Field name={type} id={type} component="input" type="checkbox" value={type} />{' '}
            {displayText ? displayText : upperFirst(type)}
        </label>
    );
}

export function GET_JOB_FORM_TABS() {
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    return {
        JOB_BASICS: {
            name: '1. Fill Up Job Details',
            order: 1,
            key: 'JOB_BASICS',
            isRequired: true,
            isSubmit: includes([FLEETX_ACCOUNTS.GROFERS], accountId) ? false : true,
            configName: 'jobBasics',
        },
        ROUTE_DETAILS: {
            name: '2. Route Details',
            order: 2,
            key: 'ROUTE_DETAILS',
            isRequired: true,
            isSubmit: true,
            configName: 'routeDetails',
        },
        UPLOAD_DOCUMENTS: {
            name: '3. Upload Documents',
            order: 3,
            key: 'UPLOAD_DOCUMENTS',
            isRequired: false,
            isSubmit: true,
            configName: 'uploadDocuments',
        },
        EXPENSE_DETAILS: {
            name: '4. Expense Details',
            order: 4,
            key: 'EXPENSE_DETAILS',
            isRequired: false,
            isSubmit: true,
            configName: 'expenseDetails',
        },
    };
}

export function GET_SPOT_AUCTION_FORM_TABS() {
    //const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    return {
        ADD_AUCTION: {
            name: 'Add Auction',
            order: 1,
            key: 'ADD_AUCTION',
            isRequired: true,
            isSubmit: true,
            configName: 'addAuction',
        },
        SHIPMENT_DETAILS: {
            name: 'Shipment Details',
            order: 2,
            key: 'SHIPMENT_DETAILS',
            isRequired: true,
            isSubmit: true,
            configName: 'shipmentDetails',
        },
        ROUTE_DETAILS: {
            name: 'Route Details',
            order: 3,
            key: 'ROUTE_DETAILS',
            isRequired: true,
            isSubmit: true,
            configName: 'routeDetails',
        },
    };
}

export function GET_VENDOR_FORM_TABS() {
    return {
        CONTACT_INFO: {
            name: 'Contract Info',
            order: 1,
            key: 'CONTACT_INFO',
            isRequired: false,
            isSubmit: false,
            configName: 'contactInfo',
        },
        MORE_INFO: {
            name: 'More Info',
            order: 2,
            key: 'MORE_INFO',
            isRequired: false,
            isSubmit: false,
            configName: 'moreInfo',
        },
        CONTRACT_DETAILS: {
            name: 'Vehicle Details',
            order: 3,
            key: 'CONTRACT_DETAILS',
            isRequired: false,
            isSubmit: false,
            configName: 'contractDetails',
        },
        DOCUMENT: {
            name: 'Upload Documents',
            order: 4,
            key: 'DOCUMENT',
            isRequired: false,
            isSubmit: true,
            configName: 'document',
        },
    };
}

export function GET_PTL_VENDOR_FORM_TABS() {
    return {
        CONTACT_INFO: {
            name: 'Contact Info',
            order: 1,
            key: 'CONTACT_INFO',
            isRequired: false,
            isSubmit: false,
            configName: 'contactInfo',
        },
        MORE_INFO: {
            name: 'More Info',
            order: 2,
            key: 'MORE_INFO',
            isRequired: false,
            isSubmit: false,
            configName: 'moreInfo',
        },
        TRIP_DETAILS: {
            name: 'Trip Details',
            order: 3,
            key: 'TRIP_DETAILS',
            isRequired: false,
            isSubmit: false,
            configName: 'tripDetails',
        },
        DOCUMENT: {
            name: 'Upload Documents',
            order: 4,
            key: 'DOCUMENT',
            isRequired: false,
            isSubmit: true,
            configName: 'document',
        },
    };
}

export const ROUTE_FORM_TABS = {
    ROUTE_POINTS: { name: 'Route Points', order: 1, key: 'ROUTE_POINTS', isRequired: true, isSubmit: true },
    ROUTE_EXPENSES: {
        name: 'Route Expense',
        order: 2,
        key: 'ROUTE_EXPENSES',
        isRequired: false,
        isSubmit: true,
    },
    VEHICLE_EXPENSES: {
        name: 'Vehicle Expense',
        order: 3,
        key: 'VEHICLE_EXPENSES',
        isRequired: false,
        isSubmit: true,
    },
    ROUTE_BUDGETS: {
        name: 'Route Budget',
        order: 4,
        key: 'ROUTE_BUDGETS',
        isRequired: false,
        isSubmit: true,
    },
};

export function renderDoo(toRender, dayError) {
    if (!toRender) {
        return '';
    }
    return (
        <div className="form-group row">
            <div className="col-md-3">
                <label>
                    Days of operations<abbr className="text-danger">*</abbr>
                </label>
            </div>
            <div className="col-md-9">
                {map(ALL_DAYS_TYPE, (day) => renderCheckBox(day))}
                <div className="text-help text-danger">{dayError ? 'Please select at least single day.' : ''}</div>
            </div>
        </div>
    );
}

export function renderTimeField(name) {
    return (
        <Field name={name} ref={name} className="form-control" component="select">
            {times(48, (i) => {
                const h = padStart(floor(i / 2), 2, '0');
                const m = i % 2 ? '30' : '00';
                const value = `${h}:${m}:00`;
                return (
                    <option key={i} value={value}>
                        {h}:{m}
                    </option>
                );
            })}
            <option value="23:59:00">23:59</option>
        </Field>
    );
}

export function renderTimeRanges() {
    return (
        <div className="form-group row">
            <div className="col-md-3">
                <label>Time Range</label>
            </div>
            <div className="col-md-9 row">
                <div className="col-sm-2">{renderTimeField('sTime')}</div>
                <div className="mt-2">To</div>
                <div className="col-sm-2">{renderTimeField('eTime')}</div>
            </div>
        </div>
    );
}

export function renderTextField(field) {
    const {
        input,
        name,
        type,
        label,
        min,
        isRequired,
        helptext,
        prependText,
        colClass,
        disabled,
        meta: { touched, error },
        placeholder,
        defaultValue,
    } = field;
    const className = `form-group row ${touched && error ? 'has-danger' : ''}`;
    if (min === 0 || !!min) {
        input.min = min;
    }
    const labelClass = `${touched && error ? 'text-danger' : ''}`;
    if (!prependText) {
        return (
            <div className={className}>
                <div className={`col-md-3 ${colClass}`}>
                    <label htmlFor={name} className={labelClass}>
                        {label} {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                    </label>
                </div>
                <div className={`col-md-9 ${colClass}`}>
                    <input
                        type={type}
                        className="form-control rounded"
                        {...input}
                        disabled={disabled}
                        placeholder={placeholder}
                        {...(defaultValue ? { value: defaultValue } : {})}
                        onWheel={(e) => e.currentTarget.blur()}
                    />
                    {touched && error ? (
                        <span className="help-block text-danger">{error}</span>
                    ) : (
                        helptext && <span className="help-block">{helptext}</span>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={className}>
            <div className="col-md-3">
                <label htmlFor={name} className={labelClass}>
                    {label} {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                </label>
            </div>
            <div className="col-md-9">
                <div className="input-group">
                    <span className="input-group-addon">{prependText}</span>
                    <input type={type} className="form-control " {...input} />
                </div>
                {touched && error ? (
                    <span className="help-block text-danger">{error}</span>
                ) : (
                    helptext && <span className="help-block">{helptext}</span>
                )}
            </div>
        </div>
    );
}

export function renderAntdTextField({
    name,
    label,
    type = 'text',
    min,
    required,
    helpText,
    prependText,
    disabled,
    placeholder,
    defaultValue,
    className,
}) {
    // Prepare validation rules
    const rules = [
        {
            required,
            message: `${label} is required`,
        },
    ];

    // Add min value validation if specified
    if (typeof min === 'number') {
        rules.push({
            type: 'number',
            min,
            message: `${label} must be at least ${min}`,
        });
    }

    // Configure shared input props
    const inputProps = {
        type,
        disabled,
        placeholder,
        defaultValue,
        className,
        ...(min !== undefined && { min }),
    };

    return (
        <FxForm.Item
            name={name}
            label={
                <FxTypography.Text>
                    {label}
                    {required && <FxTypography.Text type="danger"> *</FxTypography.Text>}
                </FxTypography.Text>
            }
            rules={rules}
            help={helpText}
            labelCol={{
                span: 24,
            }}
            validateTrigger={['onChange', 'onBlur']}
            className={className}
        >
            <FxInput {...inputProps} addonBefore={prependText ? prependText : undefined} />
        </FxForm.Item>
    );
}

export function renderTextFieldStackedLabel(field) {
    const {
        input,
        name,
        type,
        label,
        min,
        isRequired,
        helptext,
        prependText,
        colClass,
        disabled,
        meta: { touched, error },
        placeholder,
    } = field;
    const className = `form-group ${touched && error ? 'has-danger' : ''}`;
    const labelClass = `${touched && error ? 'text-danger' : ''}`;
    if (min === 0 || !!min) {
        input.min = min;
    }
    if (!prependText) {
        return (
            <div className={className}>
                {label && (
                    <div className={`${colClass}`}>
                        <label htmlFor={name} className={labelClass}>
                            {label} {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                        </label>
                    </div>
                )}
                <div className={`${colClass}`}>
                    <input
                        placeholder={placeholder}
                        type={type}
                        className="form-control rounded"
                        {...input}
                        disabled={disabled}
                        onWheel={(e) => e.currentTarget.blur()}
                    />
                    {touched && error ? (
                        <span className="help-block text-danger">{error}</span>
                    ) : (
                        helptext && <span className="help-block">{helptext}</span>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={className}>
            <div>
                <label htmlFor={name} className={labelClass}>
                    {label} {isRequired ? <abbr className="text-danger">*</abbr> : ''}
                </label>
            </div>
            <div>
                <div className="input-group">
                    <span className="input-group-addon">{prependText}</span>
                    <input placeholder={placeholder} type={type} className="form-control " {...input} />
                </div>
                {touched && error ? (
                    <span className="help-block text-danger">{error}</span>
                ) : (
                    helptext && <span className="help-block">{helptext}</span>
                )}
            </div>
        </div>
    );
}

export function mappedGroups(groups) {
    return map(groups, (o) => {
        return {
            value: o.id,
            label: o.name,
        };
    });
}

export function mappedRoles(roles) {
    return map(roles, (o) => {
        return {
            value: o.id,
            label: o.name,
        };
    });
}

export function mappedVehicleTypes(vehicleTypes) {
    return map(vehicleTypes, (o) => {
        return {
            value: o.id,
            label: o.name,
        };
    });
}

export function mappedCities(cities) {
    return map(cities, (city) => {
        return {
            value: city,
            label: city,
        };
    });
}

export function mappedVehicleMaker(vehicleMakers) {
    return map(vehicleMakers, (o) => {
        return {
            value: o.id,
            label: o.name,
        };
    });
}

export function mappedVehicleModel(vehicleModels) {
    return map(vehicleModels, (o) => {
        return {
            value: o.id,
            label: o.name,
        };
    });
}

export function mappedUsers(users, showPhoneNumber = false) {
    return map(users, (o) => {
        return {
            value: o.id,
            label: showPhoneNumber ? `${o.name} (${get(o, 'phoneNumber', '-')})` : o.name,
            enabled: o.enabled,
        };
    });
}

export function mappedTechnicians(users, useNameAsValue = false) {
    return map(users, (o) => {
        const name = o.firstName || o.lastName ? `- ${o.firstName}${o.lastName ? ` ${o.lastName}` : ''}` : '';
        const phoneNumber = o.phoneNumber ? `- ${o.phoneNumber}` : '';
        return {
            value: useNameAsValue ? `${o.firstName}${o.lastName ? ` ${o.lastName}` : ''}` : o.id,
            label: `${o.id}${name}${phoneNumber}`,
        };
    });
}

export function mappedDeviceTypes(deviceTypes) {
    return map(deviceTypes, (value, key) => {
        return {
            value: key,
            label: `${key}(${value})`,
        };
    });
}

export function isAvailableInUserType(userTypesAsMap, label) {
    if (!isEmpty(userTypesAsMap)) {
        return find(userTypesAsMap, (val) => val.name === label);
    }
    return false;
}

export function mappedDriverFromUserMini(usersMini) {
    let options = [];
    forEach(usersMini, (arr) => {
        if (arr[6] && arr[6].includes(1)) {
            options.push({
                value: arr[0],
                label: arr[1] + ' ' + arr[2] + ' ' + arr[3] || '',
            });
        }
    });
    return options;
}

export function mappedUsersMini(usersMini, userTypesAsMap = {}, label, emailIdInsteadOfId) {
    let userFiltered = [];
    if (isAvailableInUserType(userTypesAsMap, label)) {
        userFiltered = filter(usersMini, (o) => {
            if (o[6] != null && !isEmpty(userTypesAsMap)) {
                let tempLabel = '';
                const type = o[6][0];
                tempLabel = get(userTypesAsMap, `${type}.name`, '');
                if (get(o[6], 'length')) {
                    if (tempLabel === label) {
                        return true;
                    } else false;
                }
            }
        });
    } else {
        userFiltered = usersMini;
    }
    return map(userFiltered, (o) => {
        const employeeId = showEmployeeCodeInUserSelect() && !!o[8] ? ` (${o[8]})` : '';
        let label = `${o[1]} ${o[2] ? o[2] : ''}${employeeId} `;
        if (o[3] != null) {
            label += ' (' + o[3] + ')';
        }
        let nameNumber = label;
        if (o[5] != null) {
            label += ' (' + o[5] + ')';
        }
        if (o[6] != null && !isEmpty(userTypesAsMap)) {
            label += ' (';
            map(o[6], (type, index) => {
                label += get(userTypesAsMap, `${type}.name`, '');
                if (get(o[6], 'length') && index !== +get(o[6], 'length') - 1) {
                    label += ', ';
                }
            });
            label += ')';
        }
        if (o[8] != null) {
            label += ` (${o[8]})`;
        }

        const enabled = o[7];

        return {
            value: emailIdInsteadOfId ? o[5] : o[0],
            label: label,
            email: o[5],
            nameNumber: nameNumber,
            phoneNumber: o[3],
            firstName: o[1],
            lastName: o[2],
            enabled: enabled,
            disabled: !enabled, // for disabling in antd forms
            employeeId: o[8],
        };
    });
}

export function mappedUsersMiniRedDisable(usersMini, userTypesAsMap = {}, label, emailIdInsteadOfId) {
    // Check if the label is in the user types map
    const isLabelAvailable = isAvailableInUserType(userTypesAsMap, label);

    // Filter users based on the label
    const userFiltered = isLabelAvailable
        ? filter(usersMini, (user) => {
              const userTypes = user[6] || [];
              const type = userTypes[0];
              return !isEmpty(userTypes) && get(userTypesAsMap, `${type}.name`, '') === label;
          })
        : usersMini;

    // Map the filtered users to a structured format
    return map(userFiltered, (user) => {
        const [id, firstName, lastName, phoneNumber, , email, userTypes, enabled, employeeId] = user;

        const employeeCode = showEmployeeCodeInUserSelect() && employeeId ? ` (${employeeId})` : '';

        // Get user type labels
        const userTypeLabels = !isEmpty(userTypes)
            ? ` (${join(
                  map(userTypes, (type) => get(userTypesAsMap, `${type}.name`, '')),
                  ', '
              )})`
            : '';

        const label = compact([
            `${firstName} ${lastName || ''}${employeeCode}`,
            phoneNumber ? `(${phoneNumber})` : '',
            email ? `${email}` : '',
            userTypeLabels,
        ]).join(' ');

        return {
            value: emailIdInsteadOfId ? email : id,
            label,
            email,
            nameNumber: label,
            phoneNumber,
            firstName,
            lastName,
            enabled,
            // disabled: !enabled,
            style: enabled ? {} : { color: COLOR_ERROR },
            employeeId,
        };
    });
}

export function mappedFuelType(fuelTypes, useNameAsValue) {
    return map(fuelTypes, (o) => {
        return {
            value: useNameAsValue ? toUpper(o.name) : o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedVehicleSize(vehicleSizes) {
    return map(vehicleSizes, (o) => {
        return {
            value: o.name,
            label: `${o.name}`,
        };
    });
}
export function mappedVehicleSizeById(vehicleSizes) {
    return map(vehicleSizes, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedVehicleState(vehicleStates) {
    return map(vehicleStates, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedVehicleStatus(vehicleStatuses) {
    return map(vehicleStatuses, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedDrivers(drivers, isTransporter = false) {
    return map(drivers, (o) => {
        return {
            value: o.id,
            label: `${isTransporter ? o.firstName + ' ' + (o.lastName ? o.lastName : '') : o.name} ${
                o.phoneNumber ? o.phoneNumber : ''
            } ${
                isTransporter ? '' : o.contact?.employee?.employeeNumber ? `(${o.contact.employee.employeeNumber})` : ''
            }`,
            enabled: o.enabled,
        };
    });
}

export function mappedContracts(contracts) {
    return map(contracts, (o) => {
        return {
            value: o.id,
            label: `${o.contractNumber}${!get(o, 'isPointBased', '') ? ',' : ''} ${
                !get(o, 'isPointBased') ? get(o, 'routes[0].name', '') : ''
            }`,
        };
    });
}

export const containerType = ['DC', 'DV', 'HC', 'RF', 'TK', 'FR', 'OT', 'VN', 'GP', 'HR', 'IN', 'PH'];
export const containerSize = ['20', '40', '45'];

export const createArrayOfObjectForOption = (arr) => {
    return map(arr, (a) => {
        return {
            label: a,
            value: a,
        };
    });
};

export function mappedDriversV2(drivers) {
    return map(drivers, (o) => {
        return {
            value: o[0],
            label: `${o[1]} ${o[2]} ${o[3]}`,
        };
    });
}

export function mappedDriversByName(drivers) {
    return map(drivers, (o) => {
        const firstName = o[1] !== null ? o[1] : '';
        const lastName = o[2] !== null ? o[2] : '';
        const phoneNumber = o[3] !== null ? o[3] : '';
        const value = firstName.concat(' ', lastName);
        const label = firstName.concat(' ', lastName, ' ', phoneNumber ? `(${phoneNumber})` : '');
        return {
            value: value,
            label: label,
            phoneNumber: phoneNumber,
        };
    });
}

export function mappedBrokers(brokers) {
    return map(brokers, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedUnsettledJobs(jobList, routeList = []) {
    const mappedRouteList = keyBy(routeList, 'id');
    return map(jobList, (o) => {
        const { movementType, routeId, startDate } = o;
        const routeName = get(mappedRouteList, `${routeId}.name`, '');

        return {
            value: o.id,
            label: `${get(o, 'name', '')}${
                startDate ? `(${getMomentTime(startDate).format(DATE_FORMAT_TIME_24)})` : ''
            } ${routeName ? ` | ${routeName}` : ''} ${movementType ? ` | ${MOVEMENT_TYPE_LABELS[movementType]}` : ''}`,
        };
    });
}

export function mappedVehicleOwenership() {
    return map(VEHICLE_OWNERSHIP_TYPE, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedTyreStatus() {
    return map(TYRE_STATUS_V2, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedAttachedTypes() {
    return map(TYRE_ATTACHED_TYPES, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedBatteryStatus() {
    return map(BATTERY_STATUS, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedWorkmode() {
    return map(WORK_MODE_NEW_LIST, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
            disabled: o.disabled,
        };
    });
}

export function mappedExpenseType() {
    return map(EXPENSE_TYPES, (o) => {
        return {
            value: o.value,
            label: `${o.label}`,
        };
    });
}

export function mappedTransactionType() {
    const showSubLabels = isAccountIdGreaterThan10K();
    return map(showSubLabels ? TRANSACTION_TYPES_WITH_LABELS : TRANSACTION_TYPES, (o) => {
        return {
            value: o.value,
            label: `${o.label}`,
        };
    });
}

export function mappedReverseTrip() {
    return map(REVERSE_TRIP, (o) => {
        return {
            value: o.value,
            label: `${o.label}`,
        };
    });
}

export function mappedContactRenewalTypes(users) {
    return map(users, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedVehicleRenewalTypes(vehicleRenewalTypes) {
    return map(vehicleRenewalTypes, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedVendors(vendors, valueAsNumber = false) {
    return map(vendors, (o) => {
        return {
            value: valueAsNumber ? o.id : `${o.id}`,
            label: `${o.name}`,
            ledgerId: `${o.ledgerId}`,
            currencyCode: `${o.currencyCode}`,
            // address: o.address,
            gstNumber: o.gstNumber,
            gstNature: `${o.gstNature}`,
            vendorFuelTypes: o.vendorFuelTypes,
        };
    });
}

export function mappedPurchaseOrders(purchaseOrders) {
    return map(purchaseOrders, (o) => {
        return {
            value: o.id,
            label: `${o.orderNumber}`,
        };
    });
}

export function mappedTransporters(transporters, valueAsString = false) {
    return map(transporters, (o) => {
        return {
            value: valueAsString ? `${o.id}` : o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedUserType(userTypes) {
    return map(userTypes, (o) => {
        return {
            value: o.id,
            label: o.name,
        };
    });
}

export function mappedInvoicesOptions(invoices) {
    return map(invoices, (o) => {
        return {
            value: o.id,
            label: `${o.id}-${o.invoiceNumber}-${o.totalAmount}`,
        };
    });
}

export function mappedInvoiceTypes() {
    return [
        {
            value: 'FREIGHT_INVOICE',
            label: 'Freight Invoice',
        },
        {
            value: 'DETENTION_INVOICE',
            label: 'Detention Invoice',
        },
    ];
}

export function mappedCustomers(
    customers,
    mapCustomersOnly = false,
    removePhoneNumber = false,
    addBusinessVerticalKey = false
) {
    const onlyCustomers = [];
    if (mapCustomersOnly) {
        map(customers, (o) => {
            if (
                showCustomerFilterIndents(window.FLEETX_LOGGED_IN_USER?.accountId)
                    ? o.type && o.type === 'CUSTOMER'
                    : true
            ) {
                onlyCustomers.push({
                    value: typeof o.id === 'number' ? `${o.id}` : o.id,
                    label: removePhoneNumber ? `${o.name}` : `${o.name}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
                    type: o.type,
                    businessVertical: addBusinessVerticalKey ? o.businessVertical?.name : undefined,
                });
            }
        });
        return onlyCustomers;
    } else {
        return map(customers, (o) => {
            return {
                value: o.id,
                label: removePhoneNumber
                    ? `${o.name}${o.code ? ` (${o.code})` : ''}`
                    : `${o.name}${o.code ? ` (${o.code})` : ''}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
                type: o.type,
                businessVertical: addBusinessVerticalKey ? o.businessVertical?.name : undefined,
            };
        });
    }
}

export function mappedCustomersNameOnly(customers, mapCustomersOnly = false, removePhoneNumber = false) {
    const onlyCustomers = [];
    if (mapCustomersOnly) {
        map(customers, (o) => {
            if (
                showCustomerFilterIndents(window.FLEETX_LOGGED_IN_USER?.accountId)
                    ? o.type && o.type === 'CUSTOMER'
                    : true
            ) {
                onlyCustomers.push({
                    value: removePhoneNumber ? `${o.name}` : `${o.name}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
                    label: removePhoneNumber ? `${o.name}` : `${o.name}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
                    type: o.type,
                });
            }
        });
        return onlyCustomers;
    } else {
        return map(customers, (o) => {
            if (isShowCodeInCustomerNameForAccount()) {
                return {
                    value: removePhoneNumber ? `${o.name}` : `${o.name}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
                    label: removePhoneNumber ? `${o.name}` : `${o.name}${o.code ? ` (${o.code})` : ''}`,
                    type: o.type,
                };
            }
            return {
                value: removePhoneNumber ? `${o.name}` : `${o.name}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
                label: removePhoneNumber ? `${o.name}` : `${o.name}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
                type: o.type,
            };
        });
    }
}

export const maskPhoneNumber = (phoneNumber) => {
    return phoneNumber ? `*******${phoneNumber.slice(-3)}` : '';
};

export const maskName = (name) => {
    if (typeof name !== 'string' || !name.trim()) {
        return '';
    }
    const nameParts = name.split(' ');
    if (nameParts.length > 1) {
        return `${nameParts[0] || nameParts[1]} *****`;
    }
    return name;
};

export function maskedMappedCustomers(
    customers,
    mapCustomersOnly = false,
    removePhoneNumber = false,
    addBusinessVerticalKey = false
) {
    return map(customers, (o) => {
        let name = maskName(o.name);
        let phoneNumber = maskPhoneNumber(o.phoneNumber);
        return {
            value: o.id,
            label: removePhoneNumber ? `${name}` : `${name}${phoneNumber ? ` (${phoneNumber})` : ''}`,
            type: o.type,
            businessVertical: addBusinessVerticalKey ? o.businessVertical?.name : undefined,
        };
    });
}

export const mappedCustomersForEway = (customers) => {
    const treeStructureCustomer = [];
    const allGst = [];
    map(customers, (cust, i) => {
        const title = get(cust, 'name');
        const value = get(cust, 'gstNumber');
        const branchDtos = get(cust, 'branchDtos', []);
        const children = [];
        if (get(branchDtos, 'length', 0)) {
            map(branchDtos, (v, j) => {
                const officeName = get(v, 'officeName');
                const gstNum = get(v, 'gstNumber');
                if (officeName && gstNum && !allGst.includes(gstNum)) {
                    allGst.push(gstNum);
                    children.push({
                        title: officeName,
                        value: `${gstNum}`,
                    });
                }
            });
        }

        if (get(children, 'length', 0) && title & value) {
            allGst.push(value);
            treeStructureCustomer.push({
                title,
                value: `${value}`,
                children,
            });
        } else if (title && value) {
            allGst.push(value);
            treeStructureCustomer.push({
                title,
                value: `${value}`,
            });
        }
    });

    return treeStructureCustomer;
};

export function mappedProducts(products, filterByMappingList) {
    const filteredProducts = filterByMappingList
        ? filter(products, (p) => includes(filterByMappingList, p.name))
        : products;
    return map(filteredProducts, (o) => {
        return {
            value: o.name,
            label: `${o.name}`,
        };
    });
}

export function mappedParty(parties, customers) {
    const partiesList = map(parties, (party) => {
        return party.id;
    });
    const partiesOption = [];
    map(customers, (o) => {
        if (includes(partiesList, o.id)) {
            partiesOption.push({
                value: o.id,
                label: `${o.name}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
                type: o.type,
            });
        }
    });
    return partiesOption;
}

export function mappedConsignee(customers, customer) {
    const consignees = [];

    map(customers, (o) => {
        if (o.type && (o.type === 'CONSIGNEE' || o.type === 'CONSIGNEE_CONSIGNOR')) {
            consignees.push({
                value: o.id,
                label: `${o.name}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
                gstNumber: get(o, 'gstNumber', ''),
            });
        }
    });

    if (customer && customer.id) {
        const consignee = find(customers, { id: customer.id.value ? customer.id.value : customer.id });
        if (!consignee) {
            if (customer.id.value) {
                consignees.push(customer.id);
            } else {
                consignees.push({ value: customer.id, label: customer.id });
            }
        } else if (consignee.type && consignee.type === 'CONSIGNOR') {
            consignees.push({
                value: consignee.id,
                label: `${consignee.name}${consignee.phoneNumber ? ` (${consignee.phoneNumber})` : ''}`,
            });
        }
    }

    return consignees;
}

export function mappedShipToPartyConsignee(customers) {
    const consignees = [];
    forEach(customers, (o) => {
        consignees.push({
            value: o.id,
            label: `${o.name}-${o.code}`,
            gstNumber: get(o, 'gstNumber', ''),
        });
    });

    return consignees;
}

export function mappedConsigneeWithMultiplePoints(customers, points) {
    const consignees = [];

    map(customers, (o) => {
        if (o.type && (o.type === 'CONSIGNEE' || o.type === 'CONSIGNEE_CONSIGNOR')) {
            consignees.push({
                value: o.id,
                label: `${o.name}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
            });
        }
    });
    if (points && points.length > 0) {
        forEach(points, function (point) {
            if (point.customer && point.customer.id && point.customer.id.value) {
                const consignee = find(customers, {
                    id: point.customer.id.value ? point.customer.id.value : point.customer.id,
                });
                if (!consignee) {
                    if (point.customer.id.value) {
                        consignees.push(point.customer.id);
                    } else {
                        consignees.push({ value: point.customer.id, label: point.customer.id });
                    }
                } else if (consignee.type && consignee.type === 'CONSIGNOR') {
                    consignees.push({
                        value: consignee.id,
                        label: `${consignee.name}${consignee.phoneNumber ? ` (${consignee.phoneNumber})` : ''}`,
                    });
                }
            }
        });
    }

    return consignees;
}

export function mappedProjects(projects) {
    return map(projects, (project) => {
        return {
            label: project.name,
            value: project.id,
        };
    });
}

export function mappedConsignmentsList(consignments, truckLoadConsignmentsList, index) {
    return map(consignments, (consignment) => {
        return {
            label: get(consignment, 'basicDetails.consignmentNumber', ''),
            value: get(consignment, 'id', ''),
        };
    });
}

export function mappedChallansList(challans, truckLoadConsignmentsList, index) {
    return map(challans, (challan) => {
        return {
            label: get(challan, 'challanNumber', ''),
            value: get(challan, 'id', ''),
        };
    });
}

export function mappedProjectsWithConsignees(customers, points, index) {
    const projects = [];
    let selectedProjects = [];
    const selectedPoint = points && points[index];
    const selectedCustomer = get(selectedPoint, 'customer.id', '');
    if (selectedCustomer) {
        const customerObj = find(customers, {
            id: toSafeInteger(selectedCustomer.value ? selectedCustomer.value : selectedCustomer),
        });
        selectedProjects = get(customerObj, 'projects', []);
    }

    if (!isEmpty(selectedProjects)) {
        map(selectedProjects, (project) => {
            projects.push({
                label: project.name,
                value: project.id,
                freightRate: project.freightRate,
            });
        });
    }
    return projects;
}

export function filterTax2OptionsBasedOnTax1(taxOptions, tax1Type) {
    return filter(taxOptions, (option) => {
        if (tax1Type === 'CGST') {
            return get(option, 'value') === 'SGST';
        } else if (tax1Type === 'SGST') {
            return get(option, 'value') === 'CGST';
        }
    });
}

export function getFreightRateValue(routePoints, index) {
    const routePoint = routePoints && routePoints[index];

    const fare =
        routePoint && routePoint.freightRate ? routePoint.freightRate : get(routePoint, 'projectId.freightRate', '');
    return fare;
}

export function mappedOnlyConsignorsConsignees(customers) {
    const filteredCustomers = [];
    forEach(customers, (o) => {
        if (includes(['CONSIGNOR', 'CONSIGNEE', 'CONSIGNEE_CONSIGNOR'], o.type)) {
            filteredCustomers.push({
                value: o.id,
                label: `${o.name}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
            });
        }
    });

    return filteredCustomers;
}

export function mappedConsignors(customers, consignorId, removePhoneNumber = false) {
    const consignors = [];

    map(customers, (o) => {
        if (o.type && (o.type === 'CONSIGNOR' || o.type === 'CONSIGNEE_CONSIGNOR')) {
            consignors.push({
                value: o.id,
                label: removePhoneNumber ? `${o.name}` : `${o.name}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
                gstNumber: get(o, 'gstNumber', ''),
            });
        } else if (!o.type) {
            consignors.push({
                value: o.id,
                label: removePhoneNumber ? `${o.name}` : `${o.name}${o.phoneNumber ? ` (${o.phoneNumber})` : ''}`,
                gstNumber: get(o, 'gstNumber', ''),
            });
        }
    });

    if (consignorId) {
        const consignor = find(customers, { id: consignorId.value ? consignorId.value : consignorId });
        if (!consignor) {
            if (consignorId.value) {
                consignors.push(consignorId);
            } else {
                consignors.push({ value: consignorId, label: consignorId });
            }
        } else if (consignor.type && consignor.type === 'CONSIGNEE') {
            consignors.push({
                value: consignor.id,
                label: `${consignor.name}${consignor.phoneNumber ? ` (${consignor.phoneNumber})` : ''}`,
            });
        }
    }

    return consignors;
}

export function getBranchesFromCustomer(customers, id, name = false) {
    const customer = find(customers, { id });
    let branches = [];
    if (customer && customer.branchDtos) {
        branches = map(customer.branchDtos, (branch) => {
            return {
                value: branch.id,
                label: (name ? `${branch.officeName},` : '') + `${branch.address.state},${branch.gstNumber}`,
                gstNumber: branch.gstNumber,
                state: branch.address.state,
            };
        });
    }

    return branches;
}

export function getBranchesFromSingleCustomer(customer, name = false, branchDtos = null) {
    let branches = [];
    if (customer || branchDtos) {
        branches = map(branchDtos || customer.branchDtos, (branch) => {
            return {
                value: branch.id,
                label: (name ? `${branch.officeName},` : '') + `${branch.address.state},${branch.gstNumber}`,
                gstNumber: branch.gstNumber,
                state: branch.address.state,
            };
        });
    }

    return branches;
}

export function getGstNumber(customers, customerId, branchId, customerDetails) {
    if ((customers || customerDetails) && customerId) {
        const customer = customerDetails ? customerDetails : find(customers, { id: customerId });
        if (branchId) {
            const branch = find(customer.branchDtos, { id: branchId });
            return branch?.gstNumber;
        } else {
            return customer?.gstNumber;
        }
    }
}

export function mappedJobBookingStatus(jobBookingStatus) {
    return map(jobBookingStatus, (status) => {
        return {
            label: `${status.label}`,
            value: status.value,
        };
    });
}

export function mappedServiceTasks(serviceTasks, serviceTaskId, useNameAsId = false) {
    const serviceTaskArr = [];
    if (serviceTaskId) {
        forEach(serviceTasks, function (value) {
            serviceTaskArr.push({ value: value.id, label: `${value.name}` });
        });

        const serviceTask = find(serviceTasks, { id: serviceTaskId.value ? serviceTaskId.value : serviceTaskId });
        if (!serviceTask) {
            if (serviceTaskId.value) {
                serviceTaskArr.push(serviceTaskId);
            } else {
                serviceTaskArr.push({ value: serviceTaskId, label: serviceTaskId });
            }
        }

        return serviceTaskArr;
    } else {
        return map(serviceTasks, (o) => {
            return {
                value: useNameAsId ? o.name : o.id,
                label: `${o.name}`,
                type: o.type,
                vehicleDomain: o.vehicleDomain,
            };
        });
    }
}

export function mappedVehicles(vehicleData, groupIds, allOptions) {
    let vehicles = [];
    if (allOptions) {
        vehicles.push({
            value: 0,
            label: 'All Vehicles',
        });
    }

    const groupIdsEmpty = isEmpty(groupIds) ? true : false;

    const transformGroupIds = map(groupIds, (group) => (group.value != null ? group.value : group));

    map(vehicleData, (o) => {
        if (groupIdsEmpty || (o.group && includes(transformGroupIds, o.group.id))) {
            vehicles.push({
                value: o.id,
                label: `${o.name} ${o.licensePlate}`,
                groupId: get(o, 'group.id', 0),
            });
        }
    });

    return vehicles;
}

export function mappedVehiclesMini(vehicleData, allOptions, includeOnlyVehiclesWithDevices, groupList = []) {
    let vehicles = [];
    if (allOptions) {
        vehicles.push({
            value: 0,
            label: 'All Vehicles',
        });
    }
    const showGroup = !isEmpty(groupList);
    const systemTags = getSystemTagIdForFiltering();
    map(vehicleData, (o) => {
        let customFieldsMappings = get(o, 'customFieldsMappings', []);
        let skipTaggedVehicle = false;
        if (!isEmpty(systemTags)) {
            map(systemTags, (tag) => {
                if (includes(o.tagIdsSet, tag)) {
                    skipTaggedVehicle = true;
                }
            });
        }
        if ((!includeOnlyVehiclesWithDevices || !!o['deviceId']) && !skipTaggedVehicle) {
            let groupName = '';
            let groupNameString = '';
            if (showGroup) {
                const group = find(groupList, { id: o.groupId });
                groupName = get(group, 'name', '');
                groupNameString = `(${get(group, 'name', '')})`;
            }
            vehicles.push({
                value: o['id'],
                label: `${o['name']} ${o['licensePlate']}${groupName ? groupNameString : ''}`,
                groupId: `${!!o['groupId'] && parseInt(o['groupId']) > 0 ? parseInt(o['groupId']) : 0}`,
                vehicleModel: get(o, 'vehicleModel.name', ''),
                vehicleMaker: get(o, 'vehicleModel.vehicleMaker.name', ''),
                customFieldsMappings,
            });
        }
    });
    //o[4] = 'Vehicle Type'

    return vehicles;
}

export function mappedVehiclesMiniWithSizeFilter(vehicleData, vehicleSizeId) {
    let vehicles = [];
    map(vehicleData, (o) => {
        if (o.vehicleSizeEntityId == vehicleSizeId || o.vehicleSpecs?.vehicleSizeEntityId == vehicleSizeId) {
            vehicles.push({
                value: o.id,
                label: `${o.name} ${o.licensePlate}`,
                groupId: `${!!o.groupId && parseInt(o.groupId) > 0 ? parseInt(o.groupId) : 0}`,
            });
        }
    });

    return vehicles;
}

export function mappedIndentVehiclesWithSizeFilter(vehicleData, vehicleSizeId) {
    let vehicles = [];
    map(vehicleData, (o) => {
        if (get(o, 'vehicleSpecs.vehicleSizeEntity.id') == vehicleSizeId) {
            vehicles.push({
                value: o.id,
                label: `${o.name} ${o.licensePlate}`,
                groupId: `${!!o.groupId && parseInt(o.groupId) > 0 ? parseInt(o.groupId) : 0}`,
            });
        }
    });

    return vehicles;
}

export function mappedGeofences(geofences, vehicleIds, groupIds, type) {
    let transformGroupIds = map(groupIds, (group) => (group.value != null ? group.value : group));
    if (isEmpty(transformGroupIds)) {
        transformGroupIds = [
            ...transformGroupIds,
            ...map(vehicleIds, (vehicle) => (vehicle.groupId ? vehicle.groupId : 0)),
        ];
    }
    const transformVehicleIds = map(vehicleIds, (vehicle) => (vehicle.value != null ? vehicle.value : vehicle));

    let geofenceOptions = [];
    map(geofences, (geofence) => {
        let toInclude = false;
        if (type && !geofence.type.match(type)) {
            return;
        }

        if (includes(transformGroupIds, 0) && includes(transformVehicleIds, 0)) {
            toInclude = true;
        }
        if (isEmpty(geofence.vehicleIds) && isEmpty(geofence.groupIds)) {
            // first check is both are empty
            toInclude = true;
        } else if (
            (!isEmpty(geofence.vehicleIds) && includes(geofence.vehicleIds, 0)) ||
            (isEmpty(geofence.vehicleIds) && !isEmpty(geofence.groupIds))
        ) {
            //check for all vehicles or only groups
            if (isEmpty(geofence.groupIds)) {
                toInclude = true;
            } else {
                map(transformGroupIds, (groupId) => {
                    if (includes(geofence.groupIds, groupId)) {
                        toInclude = true;
                    }
                });
            }
        } else if (!isEmpty(geofence.vehicleIds) && isEmpty(geofence.groupIds)) {
            // check for only vehicles
            if (includes(geofence.vehicleIds, 0)) {
                toInclude = true;
            } else {
                map(transformVehicleIds, (vehicleId) => {
                    if (includes(geofence.vehicleIds, vehicleId)) {
                        toInclude = true;
                    }
                });
            }
        } else {
            // check for both
            map(transformGroupIds, (groupId) => {
                if (includes(geofences.groupIds, groupId)) {
                    map(transformVehicleIds, (vehicleId) => {
                        if (includes(geofences.vehicleIds, vehicleId)) {
                            toInclude = true;
                        }
                    });
                }
            });
        }
        if (toInclude) {
            geofenceOptions.push({
                value: geofence.id,
                label: geofence.name,
            });
        }
    });
    return geofenceOptions;
}

export function mappedDuties(duties, selectedDutiesToExclude, currentDutyId) {
    const filteredDuties = filter(duties, (d) => {
        let isSelected = false;
        if (d.customerInvoiceId && d.id !== currentDutyId) {
            return false;
        }
        map(selectedDutiesToExclude, (selectedDuty) => {
            if (d.id !== currentDutyId && get(selectedDuty, 'id.value', selectedDuty.id) === d.id) {
                isSelected = true;
            }
        });
        return !isSelected;
    });

    return map(filteredDuties, (o) => {
        return {
            value: get(o, 'id.value', o.id),
            label: `Slip: ${o.slipNumber}, Vehicle: ${o.vehicle.licensePlate}, Driver: ${o.driver.name}`,
        };
    });
}

export function mappedJobs(jobs, selectedJobsToExclude, currentJobId) {
    const filteredJobs = filter(jobs, (d) => {
        let isSelected = false;
        if (d.customerInvoiceId && d.id !== currentJobId) {
            return false;
        }
        map(selectedJobsToExclude, (selectedJob) => {
            if (d.id !== currentJobId && get(selectedJob, 'id.value', selectedJob.id) === d.id) {
                isSelected = true;
            }
        });
        return !isSelected;
    });

    return map(filteredJobs, (o) => {
        return {
            value: get(o, 'id.value', o.id),
            label: `Job: ${o.name}, Vehicle: ${get(o, 'vehicle.licensePlate', '')},  From: ${
                first(o.route.routePoints).address
            } to: ${last(o.route.routePoints).address}`,
        };
    });
}

export function mappedJobNameAndStartDate(jobs) {
    return map(jobs, (o) => {
        return {
            value: get(o, 'id.value', o.id),
            label: `${o.name} | ${getHumanizeTime(o.startDate)}`,
        };
    });
}

export function mappedJobsForFreightSettlement(jobs, formType, jobPoNumber) {
    let _jobs = [];
    let _jobPoNumber = null;
    const isOnlyPOJobsAllowed = isAllowOnlyPONumberJobsInFreightSettlement();
    forEach(jobs, (o) => {
        let isPOJob = false;
        let numbers = [];
        //TODO: Replace these two forEach with find to refactor this
        forEach(o.jobAnalytics, (jobAnalytic) => {
            forEach(jobAnalytic.billNumbers, (billNumber) => {
                if (isOnlyPOJobsAllowed && billNumber.type === 'PO') {
                    isPOJob = true;
                    _jobPoNumber = billNumber.value;
                }
                if (['LR', 'VENDOR_LR', 'DOCKET'].includes(billNumber.type)) {
                    numbers.push(billNumber.value);
                }
            });
        });
        let includeJob = isOnlyPOJobsAllowed ? isPOJob && (jobPoNumber ? jobPoNumber === _jobPoNumber : true) : true;
        if (includeJob) {
            const gpsPresent = isJobsDisabledForNonTrackInFreightSettlement() ? o.currentKm > 5 : true;
            const lr = uniq(numbers);
            _jobs.push({
                value: get(o, 'id.value', o.id),
                label:
                    (formType.name === 'PTL'
                        ? `${lr.length > 0 ? `${lr[0]} |` : ''} ${getHumanizeTime(o.startDate)} | ${o.name}`
                        : `${lr.length > 0 ? `${lr[0]} |` : ''} ${o.vehicle?.licensePlate || ''} | ${getHumanizeTime(
                              o.startDate
                          )} | ${o.name}`) +
                    ' ' +
                    (gpsPresent ? '' : ' (GPS not present)'),
                isDisabled: !gpsPresent,
            });
        }
    });
    return _jobs;
}

export function mappedInvoices() {
    return map(keys(INVOICE_STATUS), (key) => {
        return {
            value: INVOICE_STATUS[key].id,
            label: INVOICE_STATUS[key].name,
        };
    });
}

export function mappedAllAccounts(allAccounts, valueKey = 'email', disableExpiredAccounts) {
    let disabled = false;
    let swap = {};

    let accounts = map(allAccounts, (account) => {
        if (disableExpiredAccounts) {
            disabled = account.status === 'INACTIVE';
        }

        return {
            label: `[${account.id}] ${account.name} (${account.email}) `,
            name: account.name,
            email: account.email,
            id: account.id,
            value: account[valueKey],
            disabled: disabled,
            isInactive: account.status === 'INACTIVE',
        };
    });
    // to bring 113 on first position
    if (!isEmpty(accounts) && accounts?.length > 1) {
        swap = accounts[0];
        accounts[0] = accounts[1];
        accounts[1] = swap;
    }
    return accounts;
}

export function mappedAllAccountsIdFirst(allAccounts, valueKey = 'email', disableExpiredAccounts) {
    let disabled = false;

    return map(allAccounts, (account) => {
        if (disableExpiredAccounts) {
            disabled = account.status === 'INACTIVE';
        }

        return {
            label: `[id:${account.id}] ${account.name} (${account.email})`,
            name: account.name,
            email: account.email,
            id: account.id,
            value: account[valueKey],
            disabled: disabled,
            isInactive: account.status === 'INACTIVE',
        };
    });
}

export function mappedAccountsById(allAccounts) {
    return map(allAccounts, (account) => {
        return {
            label: account.id,
            value: account.id,
        };
    });
}

export function mappedAddressBook(addressbook) {
    return map(addressbook, (o) => {
        return createTildeValueFromAddress(o);
    });
}

export const createTildeValueFromAddress = (o) => {
    const address = combineNameAddress(o.name, o.address);
    const parsedAddress = parseAddress(address);
    if (o.id) {
        return {
            value: `${o.latitude}~${o.longitude}~${parsedAddress}~${o.id}`,
            label: `${o.name}`,
            address: o.address,
        };
    } else {
        return {
            value: `${o.latitude}~${o.longitude}~${parsedAddress}`,
            label: o.name,
            address: o.address,
        };
    }
};

export const createIdValueFromAddress = (o, type = 'address', isIdNumber = false) => {
    const address = combineNameAddress(o.name, o.address);
    const parsedAddress = parseAddress(address);
    if (o.id && type === 'address') {
        return {
            value: isIdNumber ? o.id : `${o.id}`,
            label: `${o.name}`,
            address: o.address,
        };
    } else if (o.addressBookId && type === 'route') {
        return {
            value: isIdNumber ? o.addressBookId : `${o.addressBookId}`,
            label: `${o.name}`,
            address: o.address,
        };
    } else {
        return {
            value: `${o.latitude}~${o.longitude}~${parsedAddress}`,
            label: o.name,
            address: o.address,
        };
    }
};

export function mappedAddressBookWithIdMapping(addressBook, isIdNumber, addressBookType = []) {
    let options = [];
    forEach(addressBook, (o) => {
        if (addressBookType.length) {
            if (includes(addressBookType, o.addressBookType)) {
                options.push(createIdValueFromAddress(o, 'address', isIdNumber));
            }
        } else {
            options.push(createIdValueFromAddress(o, 'address', isIdNumber));
        }
    });
    return options;
}

export function mappedCityAddressBookWithIdMapping(addressbook) {
    let cityarr = uniq(
        map(addressbook, (o) => {
            return get(o, 'city', '').toLowerCase();
        })
    );
    return map(cityarr, (city) => {
        return {
            value: city,
            label: city,
        };
    });
}

export function mappedRoutes(routes, valueAsString = false) {
    return map(routes, (o) => {
        return {
            value: valueAsString ? `${o.id}` : o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedStages(stages, valueAsString = false) {
    return map(stages, (o) => {
        return {
            value: valueAsString ? `${o.id}` : o.id,
            label: `${o.stageName}`,
        };
    });
}

export function mappedOrder(orders, valueAsString = false) {
    return map(orders, (o) => {
        return {
            value: valueAsString ? `${o.id}` : o.id,
            label: `${o.documentNumber}`,
        };
    });
}

export function mappedRouteName(routes) {
    return map(routes, (o) => {
        return {
            value: o.name,
            label: `${o.name}`,
        };
    });
}

export function mappedAllAddressBook(addressBook, addressBookType = []) {
    let options = [];
    forEach(addressBook, (o) => {
        if (addressBookType.length) {
            if (includes(addressBookType, o.addressBookType)) {
                options.push({
                    value: o.id,
                    label: `${o.name}`,
                });
            }
        } else {
            options.push({
                value: o.id,
                label: `${o.name}`,
            });
        }
    });
    return options;
}

export function mappedTags(tags) {
    return map(tags, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedCommodities(commo) {
    return map(commo, (o) => {
        return {
            value: o.id,
            label: `${o.name}`,
        };
    });
}

export function mappedTagsBranch(tags) {
    const options = [];
    map(tags, (o) => {
        if (o.type === 'BRANCH') {
            options.push({
                value: o.id,
                label: `${o.name}`,
            });
        }
    });
    return options;
}

export function mappedTripSheetStatus() {
    return map(TRIP_SHEET_STATUS, (o) => {
        return {
            value: o.value,
            label: `${o.label}`,
        };
    });
}

export function mappedPreferredTimeStamp() {
    return map(TIMESTAMP_FORMATS, (o) => {
        return {
            value: o.value,
            label: `${o.label}`,
        };
    });
}

export function mappedTruckLoadType() {
    return map(TRUCK_LOAD_TYPE, (o) => {
        return {
            value: o.value,
            label: `${o.label}`,
        };
    });
}

export function mappedJobBookingSortOn() {
    return map(JOB_BOOKING_SORT_BASED_ON, (o) => {
        return {
            value: o.value,
            label: `${o.label}`,
        };
    });
}

export function mappedAddressBookCatConfig() {
    return map(getAddressBookCategoryOptions(), (o) => {
        return {
            value: o.value,
            label: `${o.label}`,
        };
    });
}

export function mappedBusinessTypes() {
    return map(BUSINESS_TYPES, (o) => {
        return {
            value: o.value,
            label: `${o.label}`,
        };
    });
}

export const getNameForFieldArray = (key, index, editable, routeName, isRoutePointField = false) => {
    let fieldName = '';
    if (editable) {
        fieldName = `${routeName}.${index}${isRoutePointField ? '.routePoint.' : '.'}${key}`;
    } else {
        fieldName = `${routeName}.${index}.${key}`;
    }
    return fieldName;
};
export const renderVendorOption = (option, { context }) => {
    if (context === 'value') {
        return option.label;
    }
    return (
        <div>
            <div>{option.label}</div>
            {option.address && <div>{parseAddress(getAddress(option.address))}</div>}
        </div>
    );
};
export const renderClientStoreOption = (option, { context }) => {
    if (context === 'value') {
        return option.label;
    }
    return (
        <div>
            <div>{option.label}</div>
            {option.address && <div>{parseAddress(getAddress(option.address))}</div>}
        </div>
    );
};

export const FIELDTYPE = {
    TEXT: 'text',
    DATE: 'date',
    SELECT: 'select',
};
export const API_NAME = {
    TRANSPORTER: 'transporter',
    VEHICLE: 'vehicle',
    DRIVER: 'driver',
    TAGS: 'tags',
    LATEST_JOBS: 'latest_jobs',
    CONSIGNMENTS: 'consignment',
};
export const getFetchResult = async (apiName, formField) => {
    const accessToken = get(window, 'FLEETX_LOGGED_IN_DATA.access_token');
    const optionFunc = get(formField, 'optionFunc');
    let options = [];
    switch (apiName) {
        case API_NAME.TRANSPORTER:
            const respTransporter = await fetchAllTransporters(accessToken).promise;
            if (get(respTransporter, 'error')) {
                return [];
            }
            const transportersData = get(respTransporter, 'data');
            options = optionFunc(transportersData);
            return options;
        case API_NAME.CONSIGNMENTS:
            const params = {
                consignmentNature: CONSIGNMENT_NATURES.PTL,
                size: 2000,
                page: 1,
                startDate: getMomentTime().subtract(1, 'months').valueOf(),
                endDate: getMomentTime().valueOf(),
            };
            const respConsignments = await fetchConsignments(accessToken, params).promise;
            if (get(respConsignments, 'error')) {
                return [];
            }
            const consignmentsData = get(respConsignments, 'data.content');
            const respAddressBook = await fetchAddressBook(accessToken).promise;
            const addressBookData = get(respAddressBook, 'data');
            options = optionFunc(consignmentsData, arrayToObject(addressBookData, 'id'));
            return options;
        case API_NAME.VEHICLE:
            const respVehicle = await fetchVehicleListMini(accessToken).promise;
            if (get(respVehicle, 'error')) {
                return [];
            }
            const vehicleList = get(respVehicle, 'data');
            options = optionFunc(vehicleList);
            return options;
        case API_NAME.DRIVER:
            const respDriver = await fetchUsersByType(accessToken, 'driver').promise;
            if (get(respDriver, 'error')) {
                return [];
            }
            const driverList = get(respDriver, 'data.content', []);
            options = optionFunc(driverList);
            return options;
        case API_NAME.TAGS:
            const respTags = await fetchTags(accessToken).promise;
            if (get(respTags, 'error')) {
                return [];
            }
            const tagsList = get(respTags, 'data.tags');
            options = optionFunc(tagsList);
            return options;
        case API_NAME.LATEST_JOBS:
            const respLatestJobs = await fetchLastJobAllVehicles(accessToken).promise;
            if (get(respLatestJobs, 'error')) {
                return [];
            }
            const latestJobs = get(respLatestJobs, 'data');
            return latestJobs;
    }
};
export const getField = (formField, index, values, setValues) => {
    const fieldType = get(formField, 'type');
    switch (fieldType) {
        case FIELDTYPE.TEXT:
            return getTextField(formField, index, values, setValues);
        case FIELDTYPE.SELECT:
            return getSelectField(formField, index, values, setValues);
        case FIELDTYPE.DATE:
            return getDateField(formField, index, values, setValues);
        default:
            return getTextField(formField, index, values, setValues);
    }
};
export const getTextField = (formField, index) => {
    const label = get(formField, 'label');
    const isRequired = get(formField, 'isRequired');
    return (
        <Col className="col col-md-6">
            <FormikField
                key={`${label}-${index}`}
                name={index}
                type="text"
                isRequired={isRequired}
                style={{ minHeight: '38px', height: '38px' }}
                component={FxTextFieldStackedLabel}
                label={label}
            />
        </Col>
    );
};
export const getSelectField = (formField, index, values, setValues) => {
    const label = get(formField, 'label');
    const options = get(formField, 'options');
    const isRequired = get(formField, 'isRequired');
    const onChange = get(formField, 'onChange');
    const fetchedData = get(formField, 'fetchedData', []);
    return (
        <Col className="col col-md-6">
            <FormikField
                name={index}
                placeholder={`Select ${label}`}
                label={label}
                options={options}
                onChange={(opt) => onChange && onChange(opt, fetchedData, values, setValues)}
                component={FxSelectField}
                style={{ minHeight: '38px', height: '38px' }}
                isRequired={isRequired}
            />
        </Col>
    );
};
export const getDateField = (formField, index) => {
    const label = get(formField, 'label');
    const isRequired = get(formField, 'isRequired');
    const valueFormatterFunc = get(formField, 'valueFormatter');
    return (
        <Col className="col col-md-6">
            <FormikField
                name={index}
                isRequired={isRequired}
                timePicker={true}
                label={label}
                placeholder={`Enter ${label}`}
                component={FxSelectSingleDate}
                style={{ minHeight: '38px', height: '38px' }}
                valueFormatter={valueFormatterFunc ? valueFormatterFunc : null}
            />
        </Col>
    );
};
export const getFormFromConfig = (config, values, setValues) => {
    const generatedform = [];
    map(config.fields, (formField, index) => {
        generatedform.push(getField(formField, index, values, setValues));
    });
    return <>{generatedform}</>;
};

export function mappedIndentUsers(users, hideBlacklisted = false) {
    return map(users, (user) => {
        const disableBlacklist = hideBlacklisted && user.blacklisted;
        return {
            label: `${user.firstName} ${user.phoneNumber} ${disableBlacklist ? '(Blacklisted)' : ''}`,
            value: user.id,
            isDisabled: disableBlacklist,
        };
    });
}

export function mappedIndentVehicle(vehicles, indentVehicles, indentSizeId, selectedRow, accountId, troVehicles) {
    let filteredOptions = [];
    const indentType = get(selectedRow, 'type');
    map(vehicles, (vehicle) => {
        const isInTroListing =
            troVehicles && troVehicles.some((troVehicle) => get(troVehicle, 'truckNumber') === vehicle.licensePlate);
        let vehicleStatus = get(vehicle, 'vehicleStatus.id', '');
        let vehicleStatusName = get(vehicle, 'vehicleStatus.name', '');
        const excludeStatusIds = excludeVehicleStatusValue();
        const isTransporterAccount = !!accountId;
        const isDisabledVehicle = isTransporterAccount && excludeStatusIds?.includes(vehicleStatus);

        const applyStyle = isOrientCementAccount(getAccountId()) && isInTroListing;

        if (isMaerskAccount(accountId)) {
            if (indentType == 'LEASE') {
                if (get(vehicle, 'vehicleOwnership') === 'LEASED') {
                    filteredOptions.push({
                        label: vehicle.licensePlate,
                        value: vehicle.id,
                        ...(applyStyle ? { style: { color: 'green' } } : {}),
                    });
                }
            } else {
                if (get(vehicle, 'vehicleOwnership') !== 'LEASED') {
                    filteredOptions.push({
                        label: vehicle.licensePlate,
                        value: vehicle.id,
                        ...(applyStyle ? { style: { color: 'green' } } : {}),
                    });
                }
            }
        } else {
            if (get(vehicle, 'vehicleSpecs') && get(vehicle, 'vehicleSpecs.vehicleSizeEntity.id') === indentSizeId) {
                filteredOptions.push({
                    label: !isDisabledVehicle ? vehicle.licensePlate : `${vehicle.licensePlate} (${vehicleStatusName})`,
                    value: vehicle.id,
                    isDisabled: isDisabledVehicle,
                    ...(applyStyle ? { style: { color: 'green' } } : {}),
                });
            } else {
                filteredOptions.push({
                    label: !isDisabledVehicle ? vehicle.licensePlate : `${vehicle.licensePlate} (${vehicleStatusName})`,
                    value: vehicle.id,
                    isDisabled: isDisabledVehicle,
                    ...(applyStyle ? { style: { color: 'green' } } : {}),
                });
            }
        }
    });

    if (isOrientCementAccount(getAccountId())) {
        filteredOptions.sort((a, b) => {
            const aGreen = a.style?.color === 'green' ? 1 : 0;
            const bGreen = b.style?.color === 'green' ? 1 : 0;
            return bGreen - aGreen;
        });
    }

    return filteredOptions;
}

export function mappedIndentStatus(indentStatuses) {
    return map(indentStatuses, (status) => {
        return {
            label: `${status}`,
            value: status,
        };
    });
}

export const shouldShowField = (fieldsMetaData, fieldName) => {
    const isFieldsMetaData = !!fieldsMetaData && !isEmpty(fieldsMetaData);

    return (isFieldsMetaData && !(fieldsMetaData[fieldName]?.hide || false)) || !isFieldsMetaData;
};

export const getLabelFromTemplate = (fieldsMetaData, fieldName) => {
    const isFieldsMetaData = !!fieldsMetaData && !isEmpty(fieldsMetaData);

    if (isFieldsMetaData) {
        return fieldsMetaData[fieldName]?.label || null;
    } else {
        return null;
    }
};

export const getValidTaxes = (stateOfSupply, branchState, gstNature) => {
    if (!stateOfSupply || !branchState || !gstNature) {
        return [];
    }
    switch (gstNature) {
        case GST_NATURES.EXEMPTED:
            return [];
        case GST_NATURES.FCM:
        case GST_NATURES.RCM:
            if (stateOfSupply === branchState) return ['CGST', 'SGST'];
            else return ['IGST'];
        default:
            return [];
    }
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export const filtersReducer = (state, action) => {
    const { payload } = action;
    return {
        ...state,
        ...payload,
    };
};

export function getDocumentsForAPI(documents, imageTag = false, urlKey) {
    return map(documents, (doc) => {
        return imageTag
            ? doc.id
                ? doc
                : {
                      [urlKey || 'url']: getImageKitLink(doc.url),
                      imageTag: doc.documentType,
                  }
            : {
                  ...pick(doc, ['id']),
                  url: getImageKitLink(doc.url),
                  documentType: doc.documentType,
              };
    });
}

export function getTemplateOptions(templates) {
    return map(templates, (template) => {
        return {
            label: get(template, 'data.name') || template.id,
            value: template.id,
        };
    });
}

export function mappedCnNatureType() {
    return map(CN_NATURE_TYPES, (o) => {
        return {
            value: o.value,
            label: `${o.label}`,
        };
    });
}

export const removeFieldsFromValues = (values, valuesToRemove = []) => {
    forEach(valuesToRemove, (fieldName) => {
        unset(values, fieldName);
    });
};

export const VEHICLE_LOCATION = [
    { value: 'Gandhidham', label: 'Gandhidham' },
    { value: 'Jalna', label: 'Jalna' },
    { value: 'Mundra', label: 'Mundra' },
    { value: 'Morbi', label: 'Morbi' },
    { value: 'Bhiwandi', label: 'Bhiwandi' },
    { value: 'Navi Mumbai', label: 'Navi Mumbai' },
    { value: 'Rajkot', label: 'Rajkot' },
    { value: 'Ahmedabad', label: 'Ahmedabad' },
    { value: 'Dhule', label: 'Dhule' },
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Aurangabad', label: 'Aurangabad' },
    { value: 'Pune', label: 'Pune' },
    { value: 'Bechraji', label: 'Bechraji' },
    { value: 'Thane', label: 'Thane' },
    { value: 'Vadodara', label: 'Vadodara' },
    { value: 'Vapi', label: 'Vapi' },
    { value: 'Nagpur', label: 'Nagpur' },
    { value: 'JNPT', label: 'JNPT' },
    { value: 'Goregaon', label: 'Goregaon' },
    { value: 'Surat', label: 'Surat' },
    { value: 'Chennai', label: 'Chennai' },
    { value: 'Jaggiapet', label: 'Jaggiapet' },
    { value: 'Bangalore', label: 'Bangalore' },
    { value: 'Hyderabad', label: 'Hyderabad' },
    { value: 'Vijayawada', label: 'Vijayawada' },
    { value: 'Karimnagar', label: 'Karimnagar' },
    { value: 'Rajamundary', label: 'Rajamundary' },
    { value: 'Mangalore', label: 'Mangalore' },
    { value: 'Vizag', label: 'Vizag' },
    { value: 'Namakkal', label: 'Namakkal' },
    { value: 'Anantapur', label: 'Anantapur' },
    { value: 'Madurai', label: 'Madurai' },
    { value: 'Bellary', label: 'Bellary' },
    { value: 'Salem', label: 'Salem' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Bawal', label: 'Bawal' },
    { value: 'Ambala', label: 'Ambala' },
    { value: 'Faridabad', label: 'Faridabad' },
    { value: 'Bhilwara', label: 'Bhilwara' },
    { value: 'Jodhpur', label: 'Jodhpur' },
    { value: 'Gurgaon', label: 'Gurgaon' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Ludhiana', label: 'Ludhiana' },
    { value: 'Sonipath', label: 'Sonipath' },
    { value: 'Dharuhera', label: 'Dharuhera' },
    { value: 'Kotputli', label: 'Kotputli' },
    { value: 'Jamalpur', label: 'Jamalpur' },
    { value: 'G.Noida', label: 'G.Noida' },
    { value: 'Sidhrawali', label: 'Sidhrawali' },
    { value: 'Gaziabad', label: 'Gaziabad' },
    { value: 'Jammu', label: 'Jammu' },
    { value: 'Pathankot', label: 'Pathankot' },
    { value: 'Haridwar', label: 'Haridwar' },
    { value: 'Rudarpur', label: 'Rudarpur' },
    { value: 'Palwal', label: 'Palwal' },
    { value: 'Jaipur', label: 'Jaipur' },
    { value: 'Panipath', label: 'Panipath' },
    { value: 'Bilaspur', label: 'Bilaspur' },
    { value: 'Neemrana (Rajasthan)', label: 'Neemrana (Rajasthan)' },
    { value: 'Manesar', label: 'Manesar' },
    { value: 'Jajpur', label: 'Jajpur' },
    { value: 'Guwahati', label: 'Guwahati' },
    { value: 'Siliguri', label: 'Siliguri' },
    { value: 'Haldia', label: 'Haldia' },
    { value: 'Bhubaneswar', label: 'Bhubaneswar' },
    { value: 'Howrah', label: 'Howrah' },
    { value: 'Dhulagar', label: 'Dhulagar' },
    { value: 'Sambhalpur', label: 'Sambhalpur' },
    { value: 'Kharakpur', label: 'Kharakpur' },
    { value: 'Dankuni', label: 'Dankuni' },
    { value: 'Kolkata', label: 'Kolkata' },
    { value: 'Durgapur', label: 'Durgapur' },
    { value: 'Patna', label: 'Patna' },
    { value: 'Muzaffarpur', label: 'Muzaffarpur' },
    { value: 'kanpur', label: 'kanpur' },
    { value: 'Agra', label: 'Agra' },
    { value: 'VARANASI', label: 'VARANASI' },
    { value: 'Bhopal', label: 'Bhopal' },
    { value: 'Anpara', label: 'Anpara' },
    { value: 'Ranchi', label: 'Ranchi' },
    { value: 'Jhansi', label: 'Jhansi' },
    { value: 'Jamsedpur', label: 'Jamsedpur' },
    { value: 'ALLAHABAD PRAYAGRAJ', label: 'ALLAHABAD PRAYAGRAJ' },
    { value: 'Gwalior', label: 'Gwalior' },
    { value: 'Raipur', label: 'Raipur' },
    { value: 'Lucknow', label: 'Lucknow' },
    { value: 'Indore', label: 'Indore' },
    { value: 'Bilaspur', label: 'Bilaspur' },
    { value: 'Asansol', label: 'Asansol' },
];

export function incrementString(inputStr) {
    // Use regular expression to match the word and number parts
    const match = inputStr.match(/^(.*?)(0*)(\d+)$/);
    if (match) {
        const wordPart = match[1];
        const leadingZeros = match[2];
        let numberPart = match[3];

        // Increment the number part by 1
        numberPart = String(Number(numberPart) + 1);

        // Calculate the number of leading zeros to add back
        const zerosToPad = leadingZeros.length - (numberPart.length - match[3].length);
        // Combine the word, leading zeros, and incremented number parts
        return wordPart + '0'.repeat(zerosToPad) + numberPart;
    } else {
        // If there is no number at the end, simply append "1" to the input string
        return inputStr + '1';
    }
}

export function mappedUsersV2(users, showPhoneNumber = false) {
    return map(users, (o) => {
        return {
            value: o.userId,
            label: showPhoneNumber ? `${o.firstName} (${get(o, 'phoneNumber', '-')})` : o.firstName,
        };
    });
}

export const FieldTypes = {
    Input: 'text',
    Select: 'select',
    MultiSelect: 'multiselect',
    Boolean: 'boolean',
    Number: 'number',
};

export function mappedAllReasons(reasons) {
    return map(reasons, (o) => {
        return {
            value: o.id,
            label: `${o.reasonName}`,
        };
    });
}

export function mappedSubReasons(subReasons) {
    return map(subReasons, (o) => {
        return {
            value: o.id,
            label: `${o.subReasonName}`,
            description: o.description,
        };
    });
}

export const DESTINATION_STATE = [
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Jammu And Kashmir', label: 'Jammu And Kashmir' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'Daman And Diu', label: 'Daman And Diu' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Dadra And Nagar Hav.', label: 'Dadra And Nagar Hav.' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Pondicherry', label: 'Pondicherry' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Lakshadweep', label: 'Lakshadweep' },
    { value: 'West Bengal', label: 'West Bengal' },
    { value: 'Nagaland', label: 'Nagaland' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Andaman And Nicobar', label: 'Andaman And Nicobar' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Megalaya', label: 'Megalaya' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Mizoram', label: 'Mizoram' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Jharkhand', label: 'Jharkhand' },
];

export const LIVE_HALTING = [
    { value: 'false', label: 'False' },
    { value: 'true', label: 'True' },
];
export const MOVEMENT_TYPE_LABELS = {
    ONE_WAY_LOAD: 'One-way Load',
    ONE_WAY_EMPTY: 'One-way Empty',
    ROUND_TRIP_LOAD_EMPTY: 'Round Trip (Load + Empty)',
    ROUND_TRIP_LOAD_LOAD: 'Round Trip (Load + Load)',
    ROUND_TRIP_EMPTY_LOAD: 'Round Trip (Empty + Load)',
};
