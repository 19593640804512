import { round } from 'lodash';
import { isInternationalAccount } from './account_utils';
import { getCurrencyFromCountry } from './internationalization_utils';

export function getCurrentIndex(page, sizePerPage) {
    return (page - 1) * sizePerPage;
}

export const CurrencyFormat = {
    USA: 'en-US',
    IND: 'en-In',
};

export const abbreviateAmount = (amount, loggedInUser, digitAfterDecimal = 1, hideCurrency = false) => {
    if (isInternationalAccount())
        return abbreviateInternationalAmount(amount, loggedInUser, digitAfterDecimal, hideCurrency);
    if (Intl.NumberFormat) {
        if (hideCurrency) {
            return Intl.NumberFormat(loggedInUser.country === 'INDIA' ? CurrencyFormat.IND : CurrencyFormat.USA, {
                notation: 'compact',
                maximumFractionDigits: digitAfterDecimal,
            })
                .format(amount)
                .replace('T', 'K');
        } else {
            return (
                getCurrencyFromCountry(loggedInUser) +
                ' ' +
                Intl.NumberFormat(loggedInUser.country === 'INDIA' ? CurrencyFormat.IND : CurrencyFormat.USA, {
                    notation: 'compact',
                    maximumFractionDigits: digitAfterDecimal,
                })
                    .format(amount)
                    .replace('T', 'K')
            );
        }
    }
    return getCurrencyFromCountry(loggedInUser) + ' ' + amount;
};

export const abbreviateInternationalAmount = (amount, loggedInUser, digitAfterDecimal = 1, hideCurrency = false) => {
    if (amount === null || amount === undefined || isNaN(amount)) return amount;
    const currencySymbol = getCurrencyFromCountry(loggedInUser);
    const absAmount = Math.abs(amount);
    let abbreviated;
    let suffix = '';

    if (absAmount >= 1e12) {
        abbreviated = (amount / 1e12).toFixed(digitAfterDecimal);
        suffix = 'T';
    } else if (absAmount >= 1e9) {
        abbreviated = (amount / 1e9).toFixed(digitAfterDecimal);
        suffix = 'B';
    } else if (absAmount >= 1e6) {
        abbreviated = (amount / 1e6).toFixed(digitAfterDecimal);
        suffix = 'M';
    } else if (absAmount >= 1e3) {
        abbreviated = (amount / 1e3).toFixed(digitAfterDecimal);
        suffix = 'K';
    } else {
        abbreviated = amount.toFixed(digitAfterDecimal);
    }

    return hideCurrency ? `${abbreviated}${suffix}` : `${currencySymbol} ${abbreviated}${suffix}`;
};

export const getOrdinalSuffix = (index) => {
    var j = index % 10,
        k = index % 100;
    if (j == 1 && k != 11) {
        return 'st';
    }
    if (j == 2 && k != 12) {
        return 'nd';
    }
    if (j == 3 && k != 13) {
        return 'rd';
    }
    return 'th';
};

export function numberToWords(input = 0) {
    if (input == 0) {
        return 'Zero';
    }
    const a = [
        '',
        'One ',
        'Two ',
        'Three ',
        'Four ',
        'Five ',
        'Six ',
        'Seven ',
        'Eight ',
        'Nine ',
        'Ten ',
        'Eleven ',
        'Twelve ',
        'Thirteen ',
        'Fourteen ',
        'Fifteen ',
        'Sixteen ',
        'Sventeen ',
        'Eighteen ',
        'Nineteen ',
    ];
    const b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    const regex = /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/;

    const getLT20 = (n) => a[Number(n)];
    const getGT20 = (n) => b[n[0]] + ' ' + a[n[1]];

    const num = Number(input);
    if (isNaN(num)) return '';
    if (num === 0) return '';

    const numStr = num.toString();
    if (numStr.length > 9) {
        return ''; // Does not support converting more than 9 digits yet
    }

    const matchResult = ('000000000' + numStr).substr(-9).match(regex);
    if (matchResult !== null) {
        const [, n1, n2, n3, n4, n5] = matchResult;
        let str = '';
        str += n1 != 0 ? (getLT20(n1) || getGT20(n1)) + 'Crore ' : '';
        str += n2 != 0 ? (getLT20(n2) || getGT20(n2)) + 'Lakh ' : '';
        str += n3 != 0 ? (getLT20(n3) || getGT20(n3)) + 'Thousand ' : '';
        str += n4 != 0 ? getLT20(n4) + 'Hundred ' : '';
        str += n5 != 0 && str != '' ? '' : '';
        str += n5 != 0 ? getLT20(n5) || getGT20(n5) : '';

        return str.trim();
    } else {
        return '';
    }
}

export const isStringContainsNumber = (str) => /\d/.test(str || '');

export const numberToLocaleString = (amount) => {
    if (isNaN(amount)) return '0';
    return isInternationalAccount()
        ? round(Math.abs(amount)).toLocaleString(CurrencyFormat.USA)
        : round(Math.abs(amount)).toLocaleString(CurrencyFormat.IND);
};
